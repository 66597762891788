export const environment = {
  firebase: {
    projectId: 'wibo-integration',
    appId: '1:1027522683701:web:d426711057b8a55543e27c',
    databaseURL: 'https://wibo-integration-default-rtdb.firebaseio.com',
    storageBucket: 'wibo-integration.appspot.com',
    apiKey: 'AIzaSyC05lpvauCrBQzzTCzlSyKJY3Wefprd7CI',
    authDomain: 'wibo-integration.firebaseapp.com',
    messagingSenderId: '1027522683701',
  },
  production: false,
  api_url: 'https://bubba-staging.wibodev.com',
  // api_url: 'http://localhost:8000',
  auth_token_key: 'authce9d77b308c149d5992a80073637e4d5',
  site_id: '', //Este campo debe ser generado cada vez que se cree una pwa
  version: '2.2.0',
  support: {
    type: 'chat',
    url: 'https://ayuda.holadary.com/'
  }
};
