import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { Map } from '../_models/abstract-map.model';


let urls: Map = {
  /** User */
  'login': '/users/auth/basic',
  'login_guest':'/users/auth/guest',
  'reset_password': '/users/auth/reset-password',
  'current_user': '/users/me',
  'user_register': '/users',
  'google_login': '/users/gauth',
  'wallets': '/users/me/wallets',
  'wallets_benefit': '/users/me/benefits/wallets',
  'wallets_historial': '/users/me/wallets/reload/historial',
  'wallets_reload': '/users/me/wallets/reload',
  'user_check_emai': '/users/auth/check-email',
  'user_cards': '/users/me/cards',
  'card_delete': '/cards/{card_id}',

  'store_by_id': '/stores/{store_id}',
  'stores_by_site_id': '/sites/{site_id}/stores',
  'store_validation_coupon': '/stores/{store_id}/coupon',
  'store_schedule': '/stores/{store_id}/schedule/{validator}',

  /** MENU */
  'menu_by_store_id': '/stores/{store_id}/menu',
  'item_by_id_and_store_id': '/stores/{store_id}/product/{id}',
  'menu_by_site_id': '/sites/{site_id}/stores/products',
  'shopping_cart': '/stores/{store_id}/product/{item_id}/cart',
  'menu_meals_by_store_id': '/stores/{store_id}/menu/meals',
  'menu_recomendations_by_site_id': '/sites/{site_id}/stores/products/recomendations',
  /** Ordenes */
  'order_create': '/orders',
  'orders': '/users/me/orders/historial',
  'orders_active': '/users/me/orders/active',
  'order_by_id': '/users/me/orders/{order_id}',
  'order_group_create': '/orders/group',
  'order_group_by_id': '/users/me/orders/group/{order_id}',
  'orders_meals_active': '/users/me/meals/active',
  'orders_meals': '/users/me/meals/historial',
  'order_cancel':'/users/me/orders/{order_id}/cancel',
  'order_meals_by_id':'/users/me/meals/{order_meals_id}',
  'order_meals_cancele_by_id':'/users/me/meals/{id}/cancel',
  /** Banners */
  'banner_by_id': '/banners/{banners_id}',
  'banners': '/banners/all',
  /** Surveys */
  'surveys': '/surveys/pending',
  'surveys_omitted': '/surveys/{survey_id}/omitted',
  'surveys_canceled': '/surveys/{survey_id}/canceled',
  'surveys_asnwer': '/surveys/{survey_id}/asnwer',
  'surveys_tags': '/surveys/tags',
  /** In apps */
  'inapp': '/communications/inapp',
  'inapp_read': '/communications/{communication_id}/read',
  /** inobx */
  'inbox': '/communications/inbox',
  'inbox_read': '/communications/{communication_id}/read',
   /** Voucher */
   'vouchers': '/users/me/vouchers',
   'vouchers_check': '/users/me/vouchers/check',
}

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  constructor() { }

  getUrl(route: string, url_params: Map = {}) {
    var url = environment.api_url + urls[route];
    for (var key in url_params) {
      if (url.search(key) > -1) {
        url = url.replace('{' + key + '}', url_params[key])
      }
    }
    return url;
  }

  getQueryString(params: Map = {}) {

    if (params != undefined || Object.keys(params).length > 0) {
      let querystring = new HttpParams();
      for (let key in params) {
        if (![undefined].includes(params[key])) {
          querystring = querystring.set(key, params[key])
        }
      }
      return querystring;
    }

    return new HttpParams();
  }

  getHeaders(headers: Map | undefined = undefined) {
    if (headers != undefined && Object.keys(headers).length > 0) {
      return new HttpHeaders(headers);
    }
    return new HttpHeaders();
  }
}

